<template>
  <div class="student-year-form">
    <Title :label="`Анкета «Студент года»/ ${currentLabel}`" icon />
    <div class="student-year__header">
      <TopSidebar :tabData="formattedStudentYearIndividual" :key="key" />
    </div>
    <Comment
      class="student-year__comment"
      v-if="currentCategoryData.comment_employee && currentStatusForComment"
      :comment="currentCategoryData.comment_employee"
    />
    <DocsComponent
      v-show="false"
      :type="'education'"
      :project="true"
      :current-project="currentProject"
      :category_id="currentCategoryId || currentCategoryData?.info_category.id"
      :nomination_id="individualNominationValue"
      :without_validations="true"
      @up-docs="(value) => (educationDocs = value)"
    />
    <router-view :currentProject="currentProject" :type="'individual'" />
  </div>
</template>

<script>
import Title from "@/components/Blocks/Title";
import TopSidebar from "@/components/Blocks/TopSidebar";
import studentYearIndividual from "@/enums/studentYearIndividual";
import { mapGetters, mapState } from "vuex";
import Comment from "@/components/Blocks/Comment";
import DocsComponent from "@/components/Blocks/DocsComponent";

export default {
  name: "StudentYearIndividualForm",
  components: { DocsComponent, Comment, TopSidebar, Title },
  data() {
    return {
      studentYearIndividual,
      educationDocs: [],
      key: 0,
      commentStatuses: [27, 28, 35, 40],
    };
  },
  watch: {
    "$store.state.individualNominationValue"() {
      this.setNominationsData();
    },
    user() {
      this.setProjectEducationData();
    },
  },
  computed: {
    currentStatusForComment() {
      return this.currentCategoryData?.status?.key
        ? this.commentStatuses.includes(this.currentCategoryData?.status?.key)
        : false;
    },
    currentProject() {
      return Object.values(this.projectsList)?.find((item) => {
        return this.currentCategoryProject
          ? item.id === this.currentCategoryProject
          : item.id === this.currentCategoryData?.info_project?.id;
      });
    },
    currentLabel() {
      return this.currentProject?.settings?.categories.find((item) => {
        return this.currentCategoryId
          ? item.category_id === this.currentCategoryId
          : item.category_id === this.currentCategoryData?.info_category?.id;
      })?.title;
    },
    formattedStudentYearIndividual() {
      let buffer = JSON.parse(JSON.stringify(this.studentYearIndividual));

      return buffer.map((item) => {
        if (item.key === "profile") {
          item.disabled = !this.disabledFields["isIndividualNominationFilled"];
        }
        if (item.key === "performance") {
          item.disabled =
            !this.disabledFields["isIndividualNominationFilled"] ||
            !this.disabledFields["isSYPersonalFilled"];
        }
        if (item.key === "portfolio") {
          item.disabled =
            !this.disabledFields["isIndividualNominationFilled"] ||
            !this.disabledFields["isSYPersonalFilled"] ||
            !this.disabledFields["isIndividualEducationFilled"];
        }
        return item;
      });
    },
    ...mapGetters(["projectsList"]),
    ...mapState("user", ["disabledFields", "user"]),
    ...mapState([
      "currentCategoryData",
      "currentCategoryProject",
      "currentCategoryVersion",
      "individualNominationValue",
      "currentCategoryId",
      "isOldApplication",
    ]),
  },
  methods: {
    setNominationsData() {
      this.$store.commit("user/setDisabledData", [
        "isIndividualNominationFilled",
        Boolean(this.$store.state.individualNominationValue),
      ]);
    },
    setProjectEducationData() {
      this.$store.dispatch("user/checkProjectValidation", [
        this.educationDocs
          .filter((i) => {
            return i.is_required;
          })
          .map((item) => {
            return item.key_document;
          }),
        "isIndividualEducationFilled",
        "education",
      ]);
    },
  },
  mounted() {
    if (this.user) {
      this.setProjectEducationData();
    }
    this.setNominationsData();
    this.$store.commit("setNominationValue", [
      "isOldApplication",
      this.currentCategoryData?.version
        ? this.currentProject.version !== this.currentCategoryData?.version
        : this.currentProject.version !== this.currentCategoryVersion,
    ]);
  },
};
</script>

<style scoped></style>
